export const environment = {
  production: false,
  api_base_url: 'https://api-recette-tv.francetvpub.fr',
  manager_api_base_url: 'https://api-recette-manager.francetvpub.fr',
  contract_microservice_api_base_url: 'https://tv-contract-recette-api.francetvpub.fr',
  oidc: {
    client_id: 'D0B55532A2E10BF7',
    scope: 'openid email profile groups DistinguishedName',
    response_type: 'code',
    grant_type: 'authorization_code',
    auth_callback_path: '/login/callback',
    openid_authorize_endpoint: 'https://portailacces.francetvpub.fr/IdPOAuth2/authorize/ilexoidc',
  },
  pop_dom_microservice_base_url: "https://popdom-recette-api.francetvpub.fr",
};
