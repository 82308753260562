import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@core/guards/auth.guard';
import { CommercialGuardService } from '@core/guards/commercial.guard';
import { OldMenuGuardService } from '@core/guards/old-menu.guard';
import { ORGuardService } from '@core/guards/or.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/homepage/homepage.module').then(mod => mod.HomepageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./features/login/login.module').then(mod => mod.LoginModule),
  },
  {
    path: 'tv-adressee',
    loadChildren: () => import('./features/tv-adressee/tv-adressee.module').then(mod => mod.TvAdresseeModule),
    canActivate: [AuthGuardService, OldMenuGuardService],
  },
  {
    path: 'tv-lineaire',
    loadChildren: () => import('./features/tv-lineaire/tv-lineaire.module').then(mod => mod.TvLineaireModule),
    canActivate: [AuthGuardService, OldMenuGuardService],
  },
  {
    path: 'resources',
    loadChildren: () => import('./features/resources/resources.module').then(mod => mod.ResoucesModule),
    canActivate: [AuthGuardService, OldMenuGuardService],
  },
  {
    path: 'popdom',
    loadChildren: () => import('./features/popdom/popdom.module').then(mod => mod.PopDomModule),
    canActivate: [AuthGuardService, OldMenuGuardService],
   },
  {
    path: 'settings',
    loadChildren: () => import('./features/settings/settings.module').then(mod => mod.SettingsModule),

    canActivate: [AuthGuardService, OldMenuGuardService],
  },
  {
    path: 'or',
    loadChildren: () => import('./features/or/or.module').then(mod => mod.OrModule),
    canActivate: [AuthGuardService, ORGuardService],
  },
  {
    path: 'commercial',
    loadChildren: () => import('./features/commercial/commercial.module').then(mod => mod.CommercialModule),
    canActivate: [AuthGuardService, CommercialGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
